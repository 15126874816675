import { Controller } from "@hotwired/stimulus"

import { gsap, CSSPlugin, SplitText, ScrollTrigger } from "gsap/all"

gsap.registerPlugin(CSSPlugin)
gsap.registerPlugin(SplitText)
gsap.registerPlugin(ScrollTrigger)

export default class extends Controller {
  static targets = ["wording"]

  connect () {
    this.split = new SplitText(this.wordingTarget, { type: "lines" })

    this.animation = gsap.delayedCall(0.2, this.newTriggers).pause()
    this.animationOnSingleSplit()
  }

  animationOnSingleSplit () {
    this.split.lines.forEach((target) => {
      gsap.to(target, {
        backgroundPositionX: 0,
        ease: "none",
        scrollTrigger: {
          trigger: target,
          scrub: 0.5,
          start: "top center",
          end: "bottom center"
        }
      })
    })
  }

  newTriggers () {
    ScrollTrigger.getAll().forEach((trigger) => {
      trigger.kill()
    })
    this.split.split()
    this.animationOnSingleSplit()
  }
}
