import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    fileSelectedBtnClass: { type: String, default: "btn-success" },
    noFileSelectedBtnClass: { type: String, default: "btn-secondary" }
  }

  static targets = [
    "fileSelectionBtn",
    "fileField",
    "fileList"
  ]

  openFileSelection () {
    this.fileFieldTarget.click()
  }

  updateFileList (event) {
    this.fileListTarget.innerHTML = ""

    const fileCount = event.target.files.length

    if (fileCount > 0) {
      this.fileSelectionBtnTarget.classList.replace(this.noFileSelectedBtnClassValue, this.fileSelectedBtnClassValue)

      this.fileListTarget.appendChild(this.htmlFileList(event.target.files))
    } else {
      this.fileSelectionBtnTarget.classList.replace(this.fileSelectedBtnClassValue, this.noFileSelectedBtnClassValue)
    }
  }

  htmlFileList (fileList) {
    const container = document.createElement('div')
    const text = document.createElement('span')
    text.textContent = fileList.length > 1 ? I18n.attachment_form.selected_files.other : I18n.attachment_form.selected_files.one
    const list = document.createElement('ul')

    Array.from(fileList).forEach(file => {
      const listItem = document.createElement('li')
      listItem.textContent = file.name
      list.appendChild(listItem)
    })

    container.appendChild(text)
    container.appendChild(list)

    return container
  }
}
