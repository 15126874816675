import { Controller } from "@hotwired/stimulus"

import { gsap, CSSPlugin, ScrollTrigger } from "gsap/all"

gsap.registerPlugin(CSSPlugin)
gsap.registerPlugin(ScrollTrigger)

export default class extends Controller {
  static targets = ["forward", "backward"]

  connect () {
    if (this.hasForwardTarget) {
      this.animateForward(this.forwardTarget)
    }
    if (this.hasBackwardTarget) {
      this.animateBackward(this.backwardTarget)
    }
  }

  animateForward (currentDiv) {
    const slideDifference = currentDiv.offsetWidth - (window.innerWidth - 60)

    const tl = gsap.timeline({ repeat: -1 })
    tl.fromTo(currentDiv, {
      x: 0
    }, {
      x: "-" + slideDifference + "px",
      duration: currentDiv.dataset.speed,
      ease: "none"
    })
    tl.fromTo(currentDiv, {
      x: "-" + slideDifference + "px"
    }, {
      x: 0,
      duration: currentDiv.dataset.speed,
      ease: "none"
    })
  }

  animateBackward (currentDiv) {
    const slideDifference = currentDiv.offsetWidth - (window.innerWidth - 60)

    const tl = gsap.timeline({ repeat: -1 })
    tl.fromTo(currentDiv, {
      x: "-" + slideDifference + "px"
    }, {
      x: 0,
      duration: currentDiv.dataset.speed,
      ease: "none"
    })
    tl.fromTo(currentDiv, {
      x: 0
    }, {
      x: "-" + slideDifference + "px",
      duration: currentDiv.dataset.speed,
      ease: "none"
    })
  }
}
