import { Controller } from "@hotwired/stimulus"

import { gsap, CSSPlugin, ScrollTrigger } from "gsap/all"

gsap.registerPlugin(CSSPlugin)
gsap.registerPlugin(ScrollTrigger)

export default class extends Controller {
  static targets = ["background"]

  connect () {
    this.getRatio = el => window.innerHeight / (window.innerHeight + el.offsetHeight)

    gsap.fromTo(this.backgroundTarget, {
      backgroundPosition: () => "50% 0%"
    }, {
      backgroundPosition: () => `50% ${window.innerHeight * (1 - this.getRatio(this.element))}px`,
      ease: "none",
      scrollTrigger: {
        trigger: this.element,
        start: "top top",
        end: "bottom top",
        scrub: true,
        invalidateOnRefresh: true
      }
    })
  }
}
