import { Controller } from "@hotwired/stimulus"
import { gsap, CSSPlugin, Power2, ScrollTrigger } from "gsap/all"

gsap.registerPlugin(CSSPlugin)
gsap.registerPlugin(ScrollTrigger)
export default class extends Controller {
  connect () {
    const showAnim = gsap.from(this.element, {
      yPercent: -100,
      paused: true,
      ease: Power2.out,
      duration: 0.2
    }).progress(1)

    ScrollTrigger.create({
      start: "top top",
      end: "max",
      onUpdate: (self) => {
        self.direction === -1 ? showAnim.play() : showAnim.reverse()
      }
    })
  }
}
