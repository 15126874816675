import { Controller } from "@hotwired/stimulus"
import { gsap, CSSPlugin, Power4, ScrollTrigger } from "gsap/all"

gsap.registerPlugin(CSSPlugin)
gsap.registerPlugin(ScrollTrigger)

export default class extends Controller {
  static targets = ["items"]
  static values = {
    mobileBreakpoint: String
  }

  connect () {
    this.breakpoint = this.mobileBreakpointValue || '1024px'

    window.addEventListener('load', () => {
      this.setUpParallaxe()
    }, false)
  }

  setUpParallaxe () {
    if (window.matchMedia("(min-width: " + this.breakpoint + ")").matches) {
      this.itemsTargets.forEach((section) => {
        const currentSpeed = parseInt(section.dataset.speed) || -20

        gsap.to(section, {
          yPercent: currentSpeed,
          ease: Power4,
          scrollTrigger: {
            trigger: this.element,
            scrub: true
          }
        })
      })
    }
  }
}
