import { Controller } from "@hotwired/stimulus"
import { gsap, CSSPlugin, Power1, ScrollTrigger } from "gsap/all"

gsap.registerPlugin(CSSPlugin)
gsap.registerPlugin(ScrollTrigger)

export default class extends Controller {
  connect () {
    const container = this.element
    const overlay = container.querySelector('.overlay')

    container.addEventListener('mouseenter', (e) => {
      const rect = container.getBoundingClientRect()
      const offsetX = e.clientX - rect.left
      const offsetY = e.clientY - rect.top
      const width = container.offsetWidth
      const height = container.offsetHeight
      const x = (offsetX / width) * 100
      const y = (offsetY / height) * 100

      gsap.set(overlay, { width: '20px', height: '20px', top: `${y}%`, left: `${x}%`, transformOrigin: "center center" })
      gsap.to(overlay, { scale: 18, duration: 0.6, ease: Power1.out })
    })

    container.addEventListener('mouseleave', () => {
      gsap.to(overlay, { scale: 0, duration: 0.5, ease: Power1.out })
    })
  }
}
