import { Controller } from "@hotwired/stimulus"
import { gsap, Back, Power4, CSSPlugin, SplitText } from "gsap/all"

gsap.registerPlugin(CSSPlugin)
gsap.registerPlugin(SplitText)

export default class extends Controller {
  static targets = ["wording", "wrapper", "background"]

  connect () {
    const animation = gsap.timeline({})
    gsap.set(this.wrapperTarget, { autoAlpha: 1 })
    const mySplitText = new SplitText(this.wordingTarget, { type: "chars" })

    animation.from(mySplitText.chars, { opacity: 0, y: -10, ease: Back.easeInOut, stagger: { from: "start", each: 0.07 } })
    gsap.to(this.backgroundTarget, { width: "auto", ease: Power4.easeInOut, duration: "0.7" })
  }
}
